import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { HomePage } from "./pages/HomePage";
import { Searchpage } from "./pages/SearchPage";
import { SourcePage } from "./pages/SourcePage";
import { QueryClient, QueryClientProvider } from "react-query";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Logo  from "./assets/logo-colored.png";

const queryClient = new QueryClient();
const helmetContext = {};

function App() {
  return (
    <div className="App">
      <HelmetProvider context={helmetContext}>
      <Helmet>
        <meta property="og:title" content="Crawler Alpha Project Archive" />
        <meta property="og:image" content={Logo} />
        <meta property="description" content="Deep search into WoW pre-release data, we gathered tons of texts from 2000 to 2004, you can search by string patterns to get all the occurences." />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/sources" element={<SourcePage />} />
            <Route path="/search/:query" element={<Searchpage />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </QueryClientProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
